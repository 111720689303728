/*
-----------------------------------------------------------------------
	Main Content CSS
-----------------------------------------------------------------------
*/

.art-details-main {
    @media #{$lg-up-device} {
        margin-top: 90px;
    }
    @media #{$md-device} {
        margin-top: 60px;
    }
    @media #{$xs-device} {
        margin-top: 30px;
    }
    @media #{$xxs-device} {
        margin-top: 20px;
    }

    .art-details-sidebar {
        @media #{$sm-device} {
            margin-top: 30px;
        }
    }
}
