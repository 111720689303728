.f-carousel {
    --f-button-width: 38px;
    --f-button-height: 38px;

    --f-button-svg-width: 16px;
    --f-button-svg-height: 16px;
    --f-button-svg-stroke-width: 2.5;

    --f-button-color: rgb(71 85 105);
    --f-button-border-radius: 50%;
    --f-button-shadow: 0 6px 12px -2px rgb(50 50 93 / 25%),
        0 3px 7px -3px rgb(0 0 0 / 30%);

    --f-button-bg: #fff;
    --f-button-hover-bg: #f9f9f9;
    --f-button-active-bg: #f0f0f0;
}
